import React from "react";
import {inject, observer} from "mobx-react";

function MovesHistory({store}) {
    const {game} = store;
    if(!game || !game.moves) {
        return null;
    }

    function revertTo(i) {
        console.log('reverting to', i);
        game.updateMoves(game.moves.splice(0, i));
    }

    const styles = {
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 1100,
        overflow: 'auto',
        height: '100px',
        fontSize: '12px',
        backgroundColor: 'rgba(255,255,255,.4)',
        textAlign: 'left',
        direction: 'ltr'
    }

    const ulStyle = {
      margin: 0,
      padding: 0,
      listStyleType: 'none'
    };
    return <div style={styles}>
        <ul style={ulStyle}>
            {store.game.moves.reverse().map((move, i, all) => (
                <li key={i}>{all.length - i}. {move.type} {i > 0 ? <button onClick={() => revertTo(all.length - i)}>Go</button> : null}</li>
            ))}
        </ul>
    </div>
}

export default inject('store')(observer(MovesHistory));
