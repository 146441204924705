import React from "react";
import {inject, observer} from "mobx-react";
import {Alert, Button, Col, Container, Row} from "reactstrap";
import {Redirect} from "react-router";
import API from "../Util/API";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

@inject(({db, store}) => ({
    roomInfo: db.roomInfo,
    player: store.player,
    name: store.name,
    isOrganiser: store.isOrganiser,
    setCurrentName: store.setCurrentName,
    setCurrentPlayer: store.setCurrentPlayer,
    config: store.config
}))
@observer
class PreGamePage extends React.Component {
    state = {
        nameValue: '',
        userId: ''
    };

    allowJoinAsNarrator(team) {
        return !(team.players && team.players.find(p => p.narrator)) || this.props.config.allowMultipleNarrators;
    }

    get joinUrl() {
        const {roomInfo} = this.props;
        return `${window.location.origin}/join/${roomInfo.roomNumber}`;
    }

    async joinTeam(team, narrator) {
        const {roomInfo, setCurrentPlayer, name} = this.props;
        const {user} = await API.register(roomInfo.seed, name, team, narrator);
        setCurrentPlayer(team, narrator, name);
        this.setState({userId: user});
    }

    async startGame() {
        const {roomInfo} = this.props;
        try {
            this.setState({loading: true})
            await API.startGame(roomInfo.seed);
        } catch {
            this.setState({loading: false})
        }
    }

    notAlreadyJoined(team, narrator) {
        const {player} = this.props;
        if (!player) {
            return true;
        }

        return player.team !== team.number || player.narrator !== narrator;
    }

    render() {
        const {roomInfo, isOrganiser, name, setCurrentName} = this.props;

        if (!roomInfo) {
            return <Redirect to="/"/>
        }

        const {nameValue, userId} = this.state;

        const teams = roomInfo.teams.map(team => {
            const {players = [], ...props} = team;
            const hasNarrator = !!players.find(p => p.narrator);
            const hasGuesser = !!players.find(p => !p.narrator);
            return {
                players,
                hasNarrator,
                hasGuesser,
                ...props
            };
        });

        const nonFullTeam = teams.find(t => (!t.hasNarrator || !t.hasGuesser));
        const canStart = !nonFullTeam;

        return <Container fluid>
            <Row>
                <Col className={"text-center"}>
                    <div>מספר החדר</div>
                    <h2 className={"h1"}>{roomInfo.roomNumber}</h2>
                    <input value={this.joinUrl} readOnly={true} />
                </Col>
            </Row>
            {!name && <Row>
                <Col className={"text-center"}>
                    <label htmlFor="name">השם שלך:</label>
                    <input id="name" placeholder="השם שלך" value={nameValue}
                           onChange={(e) => this.setState({nameValue: e.target.value})}/>
                    <Button disabled={!nameValue} onClick={(e) => setCurrentName(nameValue)}>המשך</Button>
                </Col>
            </Row>}
            <Row className={name ? '' : 'd-none'}>
                {teams.map((team, i, teams) => (
                    <Col key={i} md={Math.floor(12 / team.length)}>
                        <div className="p-4 border-dark">
                            <h2 className={`bg-card-${team.number} p-2`}>קבוצה מספר {team.number + 1}</h2>
                            <div className={"mt-5"}>
                                <div className={"d-flex align-items-center"}>
                                    <span className={"h3 flex-grow-1 mb-0"}>מקריאים</span>
                                    {this.allowJoinAsNarrator(team) && this.notAlreadyJoined(team, true) && <Button
                                        disabled={!name}
                                        size="sm"
                                        className="float-left"
                                        color={`card-${team.number}`}
                                        onClick={() => this.joinTeam(team.number, true)}
                                    >הצטרף כמקריא</Button>}
                                </div>
                                <hr/>
                                {team.hasNarrator && <ul>
                                    {team.players.filter(p => p.narrator).map((player, j) => (
                                        <li key={j}>{player.name} {player.user === userId && <FontAwesomeIcon icon={faUser} />}</li>
                                    ))}
                                </ul>}
                                {!team.hasNarrator && <Alert color={"light"}>טרם נרשמו מקריאים</Alert>}
                                <div className={"d-flex mt-3 align-items-center"}>
                                    <span className={"h3 flex-grow-1 mb-0"}>מנחשים</span>
                                    {this.notAlreadyJoined(team, false) && <Button
                                        disabled={!name}
                                        size="sm"
                                        className="float-left"
                                        color={`card-${team.number}`}
                                        onClick={() => this.joinTeam(team.number, false)}
                                    >הצטרף כמנחש</Button>}
                                </div>
                                {team.hasGuesser && <ul>
                                    {team.players.filter(p => !p.narrator).map((player, j) => (
                                        <li key={j}>{player.name} {player.user === userId && <FontAwesomeIcon icon={faUser} />}</li>
                                    ))}
                                </ul>}
                                {!team.hasGuesser && <Alert color={"light"}>טרם נרשמו מנחשים</Alert>}
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <Row className={name ? '' : 'd-none'}>
                <Col>
                    {
                        canStart
                            ?
                            (isOrganiser ? <Button color={"primary"} onClick={() => this.startGame()}>התחל משחק</Button>
                                :
                                <Alert color={"warning"}>ממתין למנהל החדר להתחלת משחק</Alert>
                            )
                            : <Alert color={"warning"}>ממתין למצטרפים נוספים</Alert>
                    }
                </Col>
            </Row>
        </Container>
    }
}

export default PreGamePage;
