import {action, computed, observable} from "mobx";
// import list from "../data/words.json";
import list from "../data/originalWords.json";
import randomSeed from "random-seed";
import API from "../Util/API";

export const CARD_TYPE_BOMB = -1;

class Team {
    number = -1;
    cards = 0;
    players = [];

    constructor(number, players) {
        this.number = number;
        this.players = players.sort((a,b) => {
            if(a.narrator !== b.narrator) {
                return a.narrator ? -1 : 1;
            }

            return a.name > b.name ? -1 : 1;
        });
    }

    reset() {
        this.cards = 0;
    }

    addCard() {
        this.cards++;
    }

    reduceCard() {
        this.cards--;
    }

    get narratorName() {
        return this.namesToString(this.players.filter(p => p.narrator).map(p=>p.name));
    }

    get guesserName() {
        return this.namesToString(this.players.filter(p => !p.narrator).map(p=>p.name));
    }

    get names() {
        return this.namesToString(this.players.map(p => p.name));
    }

    namesToString(names) {
        if(names.length === 0) {
            return '';
        }

        if(names.length === 1) {
            return names[0];
        }

        return names.splice(0, names.length - 1).join(', ') + ' ו' + names[names.length - 1];
    }
}

export default class GameStore {
    boardWidth;
    boardHeight;
    rand;

    cards = [];
    map = [];
    cardRotations = [];
    cardVisuals = [];

    @observable winner = null; //new Team(1);
    @observable loser = null;

    @observable openCards = [];
    @observable currentTeamIndex = 0;
    @observable highlightedIndex = NaN;

    startingTeamIndex = 0;

    @observable hint = "";
    @observable hintCount = 0;
    @observable guessLeft = 0;

    @observable teams;

    roomId = null;
    round = 0;

    @observable moves;

    constructor(roomId, teams, round = 1, boardWidth = 5, boardHeight = null) {
        this.roomId = roomId;
        this.round = round;
        this.boardWidth = boardWidth;
        this.boardHeight = boardHeight || boardWidth;
        this.teams = Array(teams.length)
            .fill(null)
            .map((a, i) => new Team(i, teams[i].players));

        this.selectCard = this.selectCard.bind(this);
        this.setHint = this.setHint.bind(this);
        this.highlight = this.highlight.bind(this);

        this.resetGame();
    }

    random(max) {
        return this.rand(max);
    }

    get boardSize() {
        return this.boardWidth * this.boardHeight;
    }

    newRound(round) {
        this.round = round;
        this.resetGame();
    }

    resetGame() {
        this.rand = new randomSeed(this.roomId + "-" + this.round);
        this.currentTeamIndex = this.startingTeamIndex = this.random(this.teams.length);
        this.cards = this.getWords(this.boardSize, list);
        this.map = this.generateMap();
        this.openCards = Array(this.boardSize).fill(false);
        this.cardRotations = this.cards.map(() => {
            return this.random(10) - 5;
        });

        this.moves = [];
        this.winner = this.loser = null;

        this.teams.forEach(t=>{t.reset()})
        this.map.forEach((card, i) => {
            const team = this.getTeam(card);
            if (team && !this.openCards[i]) {
                team.addCard();
            }
        });

        this.hint = "";
        this.hintCount = 0;
        this.guessLeft = 0;

    }

    generateMap() {
        const cardsPerTeam =
            Math.floor(this.boardSize / (this.teams.length + 1));
        const counts = this.teams.flatMap((a, i) => ([{
            team: i,
            count: Math.ceil((i === this.currentTeamIndex ? cardsPerTeam + 1 : cardsPerTeam) / 2),
            visual: 0
        },{
            team: i,
            count: Math.floor((i === this.currentTeamIndex ? cardsPerTeam + 1 : cardsPerTeam) / 2),
            visual: 1
        }]));

        counts.push({
            team: CARD_TYPE_BOMB,
            count: 1,
            visual: 0
        });
        const cards = counts.reduce((carry, item) => {
            return carry.concat(Array(item.count).fill({team: item.team, visual: item.visual}));
        }, []);

        while (cards.length < this.boardSize) {
            cards.push({team: null, visual: cards.length % 2});
        }

        const shuffled = this.shuffleArray(cards);
        this.cardVisuals = shuffled.map(c => c.visual);
        return shuffled.map(c => c.team);
    }

    shuffleArray(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = this.random(i + 1);
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    getWords(size, list) {
        const words = [];
        while (words.length < size) {
            const key = this.random(list.length);
            if (words.indexOf(list[key]) !== -1) {
                continue;
            }

            words.push(list[key]);
        }

        return words;
    }

    @computed get currentTeam() {
        return this.teams[this.currentTeamIndex];
    }

    getTeam(index) {
        return this.teams.find((t) => t.number === index);
    }

    @computed get ended() {
        return this.winner !== null || this.loser !== null;
    }

    @computed get bombIndex() {
        return this.map.indexOf(CARD_TYPE_BOMB);
    }

    @action setHint(hint, count) {
        this.hint = hint;
        this.hintCount = parseInt(count);
        this.guessLeft = Math.min(this.hintCount + 1, this.currentTeam.cards);
    }

    get nextTeamIndex() {
        return (this.currentTeamIndex + 1) % this.teams.length;
    }

    @action passTurn() {
        this.currentTeamIndex = this.nextTeamIndex;
        this.setHint("", 0);
    }

    @action selectCard(index) {
        this.openCards[index] = true;
        const cardType = this.map[index];

        if (cardType === CARD_TYPE_BOMB) {
            // BOMB!!
            this.loser = this.currentTeam;
            this.winner = this.getTeam(this.nextTeamIndex); // Only correct for 2 teams
            return;
        }

        const team = this.getTeam(cardType);
        if (team) {
            team.reduceCard();

            if (team.cards === 0) {
                this.winner = team;
                return;
            }
        }

        if (cardType === this.currentTeam.number) {
            if (this.hintCount !== 0) {
                this.guessLeft--;
                if (this.guessLeft > 0) {
                    return;
                }
            } else {
                return;
            }
        }
        this.passTurn();
    }

    newRound() {

    }

    @action highlight(index = NaN) {
        this.highlightedIndex = index;
    }

    @action
    async localMove({type = "none", data = {}}) {
        this.move({type, data}, false);
        await API.move(this.roomId, type, data);
    }

    @action move({type, data = {}}, announce = true) {
        switch (type) {
            case "hint":
                this.setHint(data.hint, data.number);
                break;
            case "pass":
                this.passTurn();
                break;
            case "select":
                this.selectCard(data.card);
                break;
            case "start":
            default:
                break;
        }

        this.moves.push({type,data});
    }

    @action updateMoves(moves) {
        this.resetGame();

        if (moves.length === 0) {
            return;
        }

        moves.forEach(move => this.move(move));
    }
}
