import React from "react";
import {Card, CardBody} from "reactstrap";
import Styles from "./WordCard.module.scss";

function WordCardCover({visual, rotate, type, number}) {
    const [peaking, setPeaking] = React.useState(false);
    const cover = React.useRef(null);
    let coverElement;
    function sneakPeak(e) {
        e.preventDefault();
        setPeaking(true);
        document.addEventListener(e.type === 'mousedown' ? 'mouseup' : 'touchend', () => {
            setPeaking(false);
        }, {once: true});

    }

    React.useEffect(() => {
        if(cover.current instanceof Element) {
            cover.current.addEventListener('mousedown', sneakPeak);
            cover.current.addEventListener('touchstart', sneakPeak);
            coverElement = cover.current;
        }
        return () => {
            if(coverElement) {
                coverElement.removeEventListener('mousedown', sneakPeak);
                coverElement.removeEventListener('touchstart', sneakPeak);
            }
        }
    }, [cover]);

    const col = number % 5;
    const row = Math.floor(number / 5);
    const coverStyles = {
        transform: `rotate(${rotate}deg)`,
        zIndex: 100 - row * 5 - (5 - col)
    };

    const classes = [
        peaking ? Styles.out : '',
        peaking && number % 5 === 0 ? Styles.left : '',
        Styles.cover,
        `bg-card-${type}`,
        `type-${type}`,
        `visual-${visual}`
    ];

    return <div style={coverStyles}
            className={classes.join(' ')}
            ref={cover}
    />;
}

function WordCard({number, word, type, open, onClick, highlight, coverRotate, visual}) {
    return <Card className={`${Styles.card} ${highlight ? Styles.highlight : ''}`}>
        <CardBody className="text-center py-3">
            <div className={`${Styles.cardUpper} pb-2`}>
                <div className={Styles.visual}/>
                <div className={Styles.secondTitle}>{word}</div>
            </div>
            <div className={`bg-white h3 mb-0 ${Styles.word}`}>
                {word}
            </div>
        </CardBody>
        { !open && <div className={Styles.button} onClick={onClick}/> }
        { open && <WordCardCover number={number} rotate={coverRotate} type={type} visual={visual} />}
    </Card>
}

export default WordCard;
