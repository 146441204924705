import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import GameBoard from "../components/GameBoard";
import {Redirect, withRouter} from "react-router";
import PlayerControl from "../components/PlayerControl";
import RoundStatus from "../components/RoundStatus";
import DemoTool from "../components/DemoTool";
import MiniMap from "../components/MiniMap";
import Preloader from "../components/Preloader";
import TeamsPanel from "../components/TeamsPanel";
import MovesHistory from "../components/MovesHistory";
import Styles from "./GamePage.module.scss";

@withRouter
@inject(({store, db}) => ({
    game: store.game,
    roomInfo: db.roomInfo,
    moves: db.moves,
    startGame: store.start,
    player: store.player,
    exit: store.endGame,
    newRound: store.newRound,
    config: store.config
}))
@observer
class GamePage extends React.Component {
    state = {
        initialized: false
    };

    get receivedRoomNumber() {
        const {match} = this.props;

        return match?.params?.passcode || null;
    }

    componentDidMount() {
        const {roomInfo, startGame, moves} = this.props;

        if(roomInfo && this.receivedRoomNumber.toString() === roomInfo.roomNumber.toString()) {
            startGame(roomInfo.seed, roomInfo.round, roomInfo.teams, moves);
        }

        this.setState({
            initialized: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(Array.isArray(prevProps.moves) && Array.isArray(this.props.moves) && prevProps.moves.length !== this.props.moves.length) {
            this.props.game.updateMoves(this.props.moves);
        }
    }

    render() {
        const {player, exit, config, game,newRound, roomInfo} = this.props;
        const {initialized} = this.state;
        if(!game && initialized) {
            if(this.receivedRoomNumber) {
                return <Redirect to={`/join/${this.receivedRoomNumber}`} />
            } else {
                return <Redirect to="/"/>
            }
        }

        if(!game) {
            return <Preloader>טוען את המשחק, מיד נתחיל</Preloader>;
        }

        if(!roomInfo) {
            return <Redirect to="/"/>
        }

        return <Container fluid data-room-number={roomInfo.roomNumber}>
            <Row>
                <Col md={10}>
                    <GameBoard selectCard={(card) => game.localMove({type: 'select', data:{card}})} />
                </Col>
                <Col md={2}>
                    <div className={`d-flex flex-column ${Styles.sideBar}`}>
                        <RoundStatus />
                        {player && <><hr className="w-100 c1" /><PlayerControl setHint={(hint, number) => game.localMove({type:'hint', data:{hint, number}})} passTurn={()=>game.localMove({type: 'pass'})} /></>}
                        { player && player.narrator && <><hr className="w-100 c2" /><MiniMap className={Styles.miniMap} /></> }
                        <hr className="w-100 c3" />
                        <TeamsPanel className={"mb-2"} teams={game.teams} currentTeam={game.currentTeam} gameOver={game.ended} pendingHint={!game.hint}/>
                        <h4 className="text-center">חדר {roomInfo.roomNumber}</h4>
                    </div>
                </Col>
            </Row>
            {config.showMovesHistory && <MovesHistory />}
            <Modal isOpen={game.ended}>
                <ModalHeader>המשחק נגמר</ModalHeader>
                <ModalBody>
                    {game.winner && <h3 className={`p-2 text-center bg-card-${game.winner.number}`}>המנצחים! <br />{game.winner.names}</h3>}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => newRound()}>התחל משחק חדש</Button>{' '}
                    <Button color="primary" onClick={() => exit()}>יציאה</Button>
                </ModalFooter>
            </Modal>
            {config.showPlayerSelection && <DemoTool /> }
        </Container>
    }
}

export default GamePage;
