import React from "react";
import {Card} from "reactstrap";
import {Redirect} from "react-router";

export default class RoomInfo extends React.Component {
    render() {
        const {room} = this.props;
        if(!room) {
            return null;
        }

        return <Card>
            מספר חדר
            <h1>{room.roomNumber}</h1>
            <div>
                {room.teams && room.teams.map((team,i) => {
                    return <div key={i}>
                        <div className={`d-inline-block bg-card-${team.number}`}>קבוצה מס׳ {team.number + 1}</div>

                        {team.players && <span>
                                    <b>שחקנים:</b>
                            {team.players.map((player, i) => <span key={i}>{player.name} {player.narrator && "(מקריא)"}</span>)}
                                    </span>}
                    </div>
                })}
            </div>
        </Card>
    }
}
