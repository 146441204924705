import React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "reactstrap";
import Styles from "./PlayerControl.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";


@inject(({store}) => ({
    player: store.player,
    game: store.game,
    isPlaying: store.isPlaying
}))
@observer
class PlayerControl extends React.Component {

    state = {
        hint: '',
        number: ''
    };

    reset() {
        this.setState({
            hint: '',
            number: ''
        });
    }

    submit() {
        const {setHint} = this.props;
        setHint(this.state.hint,this.state.number);
        this.reset()
    }

    validateNumber() {
        if(isNaN(this.state.number)) {
            this.setState('');
        } else {
            if (this.state.number < 0) {
                this.setState({number: 0});
            }

            if (this.state.number > this.props.game.currentTeam.cards) {
                this.setState({number: this.props.game.currentTeam.cards});
            }
        }
    }

    form() {
        const {hint, number} = this.state;
        const {game} = this.props;

        return <div>
            <div className="d-flex">
                <input className="form-control ml-2" value={hint} onChange={(e) => this.setState({hint: e.target.value})} placeholder={"רמז"} />
                <input className={`form-control ${Styles.number}`}  value={number} type="number" min="0" max={game.currentTeam.cards} onBlur={() => this.validateNumber()} onChange={(e) => this.setState({number: e.target.value})} placeholder={"מס'"} />
            </div>
            <Button color={"primary mt-2"} className="w-100" disabled={hint === '' || number === ''} onClick={() => this.submit()}>שלח הכרזה</Button>
            </div>
    }

    render() {
        const {player, game, passTurn, isPlaying} = this.props;

        const playing = player.team === game.currentTeam.number;

        return <div>
            { !playing && <div>המתן לתור שלך</div>}
            { playing && player.narrator && !game.hint ? this.form() : null }
            { playing && game.hint && <div>
                <div>ממתין לבחירת כרטיסים</div>
                {isPlaying && <Button color={"primary w-100"} onClick={() => passTurn()}><FontAwesomeIcon className={"ml-2"} icon={faCheckCircle} /> סיימתי, העבר תור</Button>}
            </div>}
        </div>;
    }
}

export default PlayerControl;
