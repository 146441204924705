import axios from "axios";


const API = {
    base: '/api',
    async createRoom(team_count) {
        const response = await axios.post(`${API.base}/rooms`, {team_count});
        return response.data;
    },
    async joinRoom(roomNumber) {
        const response = await axios.post(`${API.base}/rooms/${roomNumber}/join`);
        return response.data;
    },
    async register(roomKey, name, team, narrator) {
        const response = await axios.post(`${API.base}/rooms/${roomKey}/players`, {name, team, narrator});
        return response.data;
    },
    async startGame(roomKey) {
        const response = await axios.post(`${API.base}/rooms/${roomKey}/start`);
        return response.data;
    },
    async move(roomKey, type, data = {}) {
        const response = await axios.post(`${API.base}/rooms/${roomKey}/moves`, {type, data});
        return response.data;
    },
    async newRound(roomKey) {
        const response = await axios.post(`${API.base}/rooms/${roomKey}/reset`);
        return response.data;
    }
};

export default API;
