import React from "react";
import {inject, observer} from "mobx-react";
import {faInfinity} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


@inject(({store}) => ({
    game: store.game,
    store: store
}))
@observer
class RoundStatus extends React.Component {
    render() {
        const {hint, hintCount, guessLeft, currentTeam} = this.props.game;
        const {isNarrating, isTeamTurn, isPlaying, player} = this.props.store;

        return <div className={"pt-4"}>
            <div className={`mb-2 p-2 text-center text-white bg-card-${currentTeam.number}`}>תור <u>{hint ? currentTeam.guesserName : currentTeam.narratorName}</u> {hint ? 'לנחש' : 'לרמוז'}</div>
            {
                hint &&
                <React.Fragment>
                    {isPlaying && <div className="alert text-center alert-success">בחר קלפים</div>}
                    <h6>הרמז:</h6>
                    <h2 className="text-center">{hint}&rlm; <span className="font-weight-normal">({hintCount})</span></h2>
                    <div>ניחושים נותרים: {hintCount === 0 ? <FontAwesomeIcon icon={faInfinity} /> : guessLeft}</div>

                </React.Fragment>
            }
            { !hint && isTeamTurn && <div className="alert alert-success text-center mb-0">{isNarrating ? 'תורך לרמוז' : 'המתן לרמז'}</div> }
            { !hint && !isTeamTurn && <div className="alert alert-warning text-center mb-0">{player ? 'ממתין לרמז מהמתחרים' : 'ממתין לרמז'}</div> }
        </div>
    }
}

export default RoundStatus;
