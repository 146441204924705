import React from "react";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";

class HomePage extends React.Component {
    render() {
        const demoGameNumber = window.localStorage.getItem('demo_game_number')
        if(demoGameNumber){
            return <Redirect to={`/join/${demoGameNumber}`} />
        }
        return <div className="d-flex h-100 flex-column justify-content-center align-items-center">
            <Link to={"/new"}>
                <Button color="primary" size={"lg"} className={"m-2"}>משחק חדש</Button>
            </Link>
            <Link to={"/join"}>
                <Button color="primary" size={"lg"} className={"m-2"}>הצטרף למשחק קיים</Button>
            </Link>
        </div>
    }
}

export default HomePage;
