// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";
import {action, observable, runInAction} from "mobx";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
// import "firebase/analytics";

// Add the Firebase products that you want to use
// import "firebase/auth";
// import "firebase/firestore";


class Firebase {
    constructor() {
        this.init();
    }

    init() {
        const firebaseConfig = {
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_DATABASE_URL,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APP_ID,
            measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        };

        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        this.analytics = firebase.analytics()
    }

    get db() {
        return firebase.database();
    }

    @observable
    roomInfo = null;

    @observable
    moves = [];

    roomRef = null;

    analytics = null;

    @action
    async connect(roomId) {
        this.roomRef = this.db.ref(`/rooms`).child(roomId);
        this.roomRef.on('value', (snapshot) => {
            runInAction(() => {
                this.roomInfo = snapshot.val() || {};
                this.moves = this.roomInfo.moves || [];
            });
        })
    }

    @action
    async disconnect() {
        if(this.roomRef) {
            this.roomRef.off();
            this.roomRef = null;
            this.roomInfo = null
            this.moves = [];
        }
    }

    logEvent = async (...props) => {
        if(this.analytics) {
            this.analytics.logEvent(...props);
        }
    }
}

export default Firebase;
