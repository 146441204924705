import React from "react";
import {inject, observer} from "mobx-react";
import Styles from "./DemoTool.module.scss";


@inject('store')
@observer
class DemoTool extends React.Component {

    render() {
        const {store} = this.props;
        const setCurrentPlayer = store.setCurrentPlayer;
        const player = store.player;

        return <div className={Styles.demoTools}>
            {store.game.teams.map((team) => <div key={team.number}>
                    Team #{team.number + 1}:
                    <button disabled={player.narrator && player.team === team.number} onClick={() => setCurrentPlayer('test', team, true)}>Narrator</button>
                    <button disabled={!player.narrator && player.team === team.number} onClick={() => setCurrentPlayer('test', team, false)}>Player</button>
            </div>
            )}
        </div>
    }
}

export default DemoTool;
