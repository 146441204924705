import React from "react";
import {inject} from "mobx-react";
import Styles from "./MiniMap.module.scss";

@inject(({store}) => ({
    boardWidth: store.game.boardWidth,
    map: store.game.map,
    startingTeamIndex: store.game.startingTeamIndex,
    highlight: store.game.highlight
}))
class MiniMap extends React.Component {
    render() {
        const {map, boardWidth, startingTeamIndex, highlight, className = ''} = this.props;

        const tileStyle = {
            width: `calc(${100 / boardWidth}% - .2rem)`,
            paddingTop: `calc(${100 / boardWidth}% - .2rem)`,
        };
        return <div className={`${Styles.map} ${className}`}>
            <div className={Styles.tiles}>
                {map.map((m, i) => <div
                    onMouseOver={() => highlight(i)}
                    onMouseOut={() => highlight(NaN)}
                    style={tileStyle}
                    className={`${Styles.tile} bg-card-${m}`} key={i}
                />)}
            </div>

            <div className={`${Styles.indicator} ${Styles.top} ${Styles.y} bg-card-${startingTeamIndex}`}/>
            <div className={`${Styles.indicator} ${Styles.bottom} ${Styles.y} bg-card-${startingTeamIndex}`}/>
            <div className={`${Styles.indicator} ${Styles.left} ${Styles.x} bg-card-${startingTeamIndex}`}/>
            <div className={`${Styles.indicator} ${Styles.right} ${Styles.x} bg-card-${startingTeamIndex}`}/>
        </div>
    }
}

export default MiniMap;
