import React from 'react';
import './App.scss';
import {Provider} from "mobx-react";
import AppStore from "./stores/AppStore";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "./pages/HomePage";
import GamePage from "./pages/GamePage";
import Firebase from "./stores/Firebase";
import NewRoomPage from "./pages/NewRoomPage";


import JoinPage from "./pages/JoinPage";
import PreGamePage from "./pages/PreGamePage";
import RoomRouter from "./components/RoomRouter";

const dbStore = new Firebase();
const appStore = new AppStore(dbStore);

function App() {
    return (
        <Provider store={appStore} db={dbStore} logEvent={dbStore.logEvent}>
            <Router>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/new" component={NewRoomPage} />
                    <Route exact path="/game/:passcode" component={GamePage} />
                    <Route exact path="/pregame" component={PreGamePage} />
                    <Route exact path="/join/:passcode?" component={JoinPage} />
                </Switch>
                <RoomRouter />
            </Router>
        </Provider>
    );
}

export default App;
