import React from "react";
import {inject, observer} from "mobx-react";
import {CardDeck} from "reactstrap";
import WordCard from "./WordCard";
import Styles from "./GameBoard.module.scss";

@inject(({store}) => ({
    game: store.game,
    map: store.game.map,
    cards: store.game.cards,
    cardRotations: store.game.cardRotations,
    cardVisuals: store.game.cardVisuals,
    openCards: store.game.openCards,
    highlightedIndex: store.game.highlightedIndex,
    isPlaying: store.isPlaying
}))
@observer
class GameBoard extends React.Component {

    onCardClick(i) {
        const {isPlaying, selectCard} = this.props;
        if(isPlaying) {
            selectCard(i);
        }
    }

    render() {
        const {map, cards, openCards, isPlaying, highlightedIndex, cardRotations, cardVisuals} = this.props;

        return <React.Fragment>
            <CardDeck className={`${!isNaN(highlightedIndex) ? Styles.hasHighlight : '' } ${Styles.deck} ${isPlaying ? 'playing' : 'pending'}`}>
                {cards.map((word, i) => <WordCard
                    onClick={() => this.onCardClick(i)}
                    word={word}
                    coverRotate={cardRotations[i]}
                    visual={cardVisuals[i]}
                    type={map[i]}
                    open={openCards[i]}
                    highlight={highlightedIndex === i}
                    key={i}
                    number={i}
                />)}
            </CardDeck>
        </React.Fragment>
    }
}

export default GameBoard;
