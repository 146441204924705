import React from "react";
import {Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn, faUser} from "@fortawesome/free-solid-svg-icons";

class Team extends React.Component {
    render() {
        const {team, current, pendingHint, gameOver,...props} = this.props;
        return (
            <div {...props}>
                <div className="text-center border-bottom border-light p-2 mb-0 d-flex justify-content-between align-items-center">
                    <div className="m-0">קלפים נותרים</div>
                    <div className="h4 m-0 ml-2">{team.cards}</div>
                </div>
                <ul className="player-list list-unstyled p-2 m-0">
                    {team.players.map((p, i) => (
                        <li key={i} className={"d-flex align-items-center"}>
                            <FontAwesomeIcon icon={faBullhorn} style={{opacity: p.narrator ? 1 : 0}}/>
                            <FontAwesomeIcon icon={faUser} className={`mx-2`} />
                            <div className="flex-grow-1 text-truncate">{p.name}</div>
                            {current && pendingHint === p.narrator && !gameOver && <Spinner type="grow" size="sm" className="ml-2" />}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default Team;
