import {inject, observer} from "mobx-react";
import {Redirect} from "react-router";
import React from "react";

function RoomRouter({db, store}) {
    const {roomInfo} = db;

    if(roomInfo && roomInfo.status === 'registration') {
        return <Redirect to={"/pregame"}/>
    }

    if(roomInfo && roomInfo.status === "playing") {
        return <Redirect to={`/game/${roomInfo.roomNumber}`} />
    }

    return null;
}

export default inject('db', 'store')(observer(RoomRouter));
