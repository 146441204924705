import {action, computed, observable, runInAction} from "mobx";
import GameStore from "./GameStore";
import API from "../Util/API";

class Player {
    constructor(name, team, narrator = false) {
        this.team = team;
        this.narrator = narrator;
        this.name = name;
    }
}

class AppStore {
    @observable game = null;
    @observable player = null;
    @observable isOrganiser = false;
    @observable name = null;

    db = null;

    config = {
        passCodeLength: parseInt(process.env.REACT_APP_ROOM_KEY_LENGTH),
        showPlayerSelection: false,
        setDummyHint: false,
        showMovesHistory: false,
        joinRoomNumber: '', // Can be used for tests
        allowMultipleNarrators: process.env.REACT_APP_ALLOW_MULTIPLE_NARRATORS !== '0'
    }

    constructor(db) {
        this.db = db;
        this.setCurrentPlayer = this.setCurrentPlayer.bind(this);
        this.endGame = this.endGame.bind(this);
        this.newRound = this.newRound.bind(this);
        this.start = this.start.bind(this);
        this.setCurrentName = this.setCurrentName.bind(this);
        this.isValidPassCode = this.isValidPassCode.bind(this);
    }

    @action setOrganiser(organiser) {
        this.isOrganiser = organiser;
    }

    @action setCurrentName(name) {
        this.name = name;
    }

    @action async endGame() {
        await this.db.disconnect();
        runInAction(() => {
            this.game = null;
            this.player = null;
        });
    }

    @action async newRound() {
        if(this.db.roomInfo) {
            await API.newRound(this.db.roomInfo.seed);
        } else {
            this.endGame();
        }
    }

    @action start(seed, round, teams,moves = []) {
        this.game = new GameStore(seed,teams,round, 5, null);
        if(this.config.setDummyHint) {
            this.game.setHint('דוגמא',0);
        }
        this.game.updateMoves(moves);
    }

    @action setCurrentPlayer(team, narrator, name) {
        this.player = new Player(name, team, narrator);
    }


    @computed get isPlaying() {
        return  this.player &&
                this.player.team === this.game.currentTeam.number &&
                this.player.narrator === false &&
                this.game.hint;
    }

    @computed get isNarrating() {
        return  this.player &&
            this.player.team === this.game.currentTeam.number &&
            this.player.narrator === true &&
            !this.game.hint;
    }

    @computed get isTeamTurn() {
        return this.player && this.player.team === this.game.currentTeam.number;
    }

    isValidPassCode(passcode) {
        return passcode && passcode.toString().length === this.config.passCodeLength && passcode.toString().match(/^\d+$/);
    }
}

export default AppStore;
