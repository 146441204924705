import React from "react";
import {inject, observer} from "mobx-react";
import {Redirect} from "react-router";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import API from "../Util/API";
import Preloader from "../components/Preloader";

@inject('store', 'db')
@observer
class NewRoomPage extends React.Component {
    state = {
        name: null,
        teamCount: 2,
        loading: false
    };

    async createRoom() {
        if(this.isFormFull === false) {
            return;
        }
        this.setState({loading: true});
        try {
            const {name, teamCount} = this.state;
            const response = await API.createRoom(teamCount);
            this.props.store.setCurrentName(name);
            this.props.store.setOrganiser(true);
            this.props.db.connect(response.id);
        } catch(error) {
            this.setState({loading: false})
        }
    }

    get isFormFull() {
        const {name} = this.state;
        return !!name;
    }

    render() {
        const {game} = this.props.store;

        if (game) {
            return <Redirect to="/game"/>;
        }

        const {name, loading} = this.state;

        return <div className="d-flex justify-content-center align-items-center h-100">
                    <Card className="w-100" style={{maxWidth: '550px'}}>
                        <CardHeader>
                            <CardTitle><h3>יצירת חדר חדש</h3></CardTitle>
                        </CardHeader>
                        <CardBody className="p-4">
                            <form onSubmit={(e) => {e.preventDefault(); this.createRoom()}}>
                                <FormGroup row>
                                    <Label xs={"3"} for={"name"}>איך קוראים לך</Label>
                                    <Col xs={9}>
                                        <Input
                                            tabIndex={1}
                                            value={name || ''}
                                            onChange={(e) => this.setState({name: e.target.value})}
                                            id={"name"}
                                        />
                                    </Col>
                                </FormGroup>
                                <div className="text-center">
                                    {!loading && <Button
                                        color={"primary"}
                                        disabled={!this.isFormFull}
                                    >
                                        פתח חדר!
                                    </Button>}
                                    {loading && <Preloader />}
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
    }
}

export default NewRoomPage;
