import React from "react";
import Team from "./Team";
import Styles from "./TeamsPanel.module.scss";

export default function TeamsPanel({teams, currentTeam, pendingHint,gameOver, ...props}) {
    return <div {...props}>
        {
            teams.map(team => (
                <div key={team.number} className={`bg-card-${team.number}`}>
                    <Team className={`${Styles.team} text-white`} team={team} current={team === currentTeam} pendingHint={pendingHint} gameOver={gameOver} />
                </div>
            ))
        }
    </div>
}
