import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Card, CardBody, Container} from "reactstrap";
import ReactCodeInput from 'react-code-input';
import Styles from "./JoinPage.module.scss";
import {withRouter} from "react-router";
import API from "../Util/API";
import RoomInfo from "../components/RoomInfo";
import Preloader from "../components/Preloader";

@withRouter
@inject('store', 'db')
@observer
class JoinPage extends React.Component {

    state = {
        roomNumber: '',
        loading: false,
        validRoom: null
    }

    constructor(props) {
        super(props);
        const {store, match} = this.props;
        this.state = {
            roomNumber: store.isValidPassCode(match?.params?.passcode) ?
                        match.params.passcode :
                        store.config.joinRoomNumber
        };
    }

    componentDidMount() {
        if(this.state.roomNumber) {
            this.joinRoom();
        }
    }

    isValidPassCode(passcode) {
        return this.props.store.isValidPassCode(passcode);
    }

    async joinRoom() {
        if(!this.isValidPassCode(this.state.roomNumber)) {
            return;
        }
        try {
            this.setState({
                loading: true,
                validRoom: false
            });
            const response = await API.joinRoom(this.state.roomNumber);
            this.setState({validRoom: true})
            this.props.store.setOrganiser(response.organiser);
            if(response.player) {
                this.props.store.setCurrentPlayer(response.player.team, response.player.narrator, response.player.name);
            }
            this.props.db.connect(response.key);
        } catch(error) {
            this.setState({loading: false, roomNumber: ''});
        }
    }

    render() {

        const {passCodeLength} = this.props.store.config;
        const {roomNumber, loading, validRoom} = this.state;
        const {roomInfo} = this.props.db;
        const {isValidPassCode} = this.props.store;

        if(loading) {
            return <Preloader>{validRoom ? 'טוען את המשחק, מיד נתחיל' : 'בודק את הפרטים'}</Preloader>
        }
        return <Container>
            <Card className="text-center">
                <CardBody>
                    <h1>הזן מספר חדר</h1>
                    <form onSubmit={e => {e.preventDefault(); this.joinRoom()}}>
                    <div className={Styles.otp}>
                        <ReactCodeInput type='number' value={roomNumber.toString()} fields={passCodeLength}
                                        pattern={/^12345678$/} onChange={(val) => this.setState({roomNumber: val})}/>
                    </div>
                    <Button color={"primary"} disabled={!isValidPassCode(roomNumber)}>הצטרף לחדר</Button>
                    </form>
                </CardBody>
            </Card>
            {roomInfo && <RoomInfo room={roomInfo} />}
        </Container>
    }
}

export default JoinPage;
